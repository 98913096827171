import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CardModule, ReactiveFormsModule, CommonModule, HttpClientModule, InputTextModule, ButtonModule, ToastModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService, private router: Router, private messageservice: MessageService) { }

  ngOnInit(): void {
    //this.initializeFormGroup();

    if (this.authenticationService.currentUserValue?.tokenInfo) {
      this.router.navigateByUrl('/Landing');
    }

  }


  // initializeFormGroup() {
  //   this.loginFormGroup = this.fb.group({
  //     email: ['', [Validators.required, Validators.email]],
  //     password: ['', [Validators.required]]
  //   })
  // }

  loginuser() {

    this.authenticationService.login(this.loginFormGroup.value).subscribe(
      response => {
        console.log(response);
        this.messageservice.add({ severity: 'success', summary: 'success', detail: ' Login Successfully' });
        localStorage.setItem('currentUser', JSON.stringify(response));

        var returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/Landing';
        this.router.navigate([returnUrl]);

      }, err => {
        console.log(err.error.Message)
        this.messageservice.add({ severity: 'error', summary: 'error', detail: 'UserName or Password is wrong' });
        this.loginFormGroup.reset();
        this.router.navigate(['']);

      })
  }
}
