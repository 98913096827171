import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  // const token = localStorage.getItem("currentUser");
  req = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      'x-api-key': 'cd3509e4-9848-4f70-9047-ce26bdd367ag',
      'x-partner-key': 'P10001'
    }
  });
  return next(req);
};
