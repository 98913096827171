<p-dialog header="Confirmation!" [(visible)]="logoutDialog" [style]="{ width: '450px' }" (onHide)="onClickNo()" 
[modal]="true" [styleClass]="changeColor ? 'energized' : ''" [closable]="false" [draggable]="false">
  <p class="pi pi-sign-out " style="margin-top: 30px; font-size: 16px; text-align: center; font-weight: bold "> Are you sure you want to sign out?</p>
  <ng-template pTemplate="footer">
    <div class="p-grid p-nogutter" style="justify-content: center; gap:10px;">
      <button pButton type="button" icon="pi pi-check" (click)="onClickYes()" label="Yes" class="yes-button"></button>
      <button pButton type="button" icon="pi pi-times" (click)="onClickNo()" label="No" class="no-button"></button>
    </div>
  </ng-template>
</p-dialog>
