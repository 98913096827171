import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { Auth } from '../shared/interface/auth';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, RouterModule,DialogModule,AvatarModule,AvatarGroupModule],
    
})
export class AppTopBarComponent {

    items!: MenuItem[];
    firstName:string;
    data:Auth

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    visible: boolean = false;
    position: "center" | "top" | "bottom" | "left" | "right" | "topleft" | "topright" | "bottomleft" | "bottomright" ;

    constructor(public layoutService: LayoutService) {
        // this.firstName = localStorage.getItem('firstName') || 'Guest';
        
     }

    

    showDialog(position: "center" | "top" | "bottom" | "left" | "right" | "topleft" | "topright" | "bottomleft" | "bottomright") {
        this.data = localStorage.getItem('currentUser');
        this.visible = true;
        this.position = position;
        console.log(this.data)
    }
}
