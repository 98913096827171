import { Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LoginComponent } from './shared/components/login/login.component';
import { LogOutComponent } from './shared/components/log-out/log-out.component';
import { canActivateGuard } from './shared/guards/auth.guard';
import { LineChartComponent } from './shared/components/line-chart/line-chart.component';

export const routes: Routes = [
    {
        path: '', component: LoginComponent
    },
    {
        path: 'Landing', component: AppLayoutComponent,
        canActivate: [canActivateGuard],
        children: [
            { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) }
        ]
    },
    {
        path: 'linechart', component: LineChartComponent,
        canActivate: [canActivateGuard]
    },
    {
        path: 'LogOut', component: LogOutComponent, canActivate: [canActivateGuard]
    },
    { path: '**', component: NotfoundComponent },

];
