import { Inject, Injectable } from '@angular/core';
import { Auth } from '../interface/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoginRequest, LoginResponse } from '../model/Login';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  challengePasswordResponse: any;

  private currentUserSubject?: BehaviorSubject<LoginResponse>;
  public currentUser?: Observable<LoginResponse>;
  loginResponse: LoginResponse;

  constructor(@Inject(DOCUMENT) private document: Document, private httpClient: HttpClient) {
      this.loginResponse = new LoginResponse();
  }

  private get currentUserBehaviorSubject(): BehaviorSubject<LoginResponse> {
    const localStorage = this.document.defaultView?.localStorage;
    if (!this.currentUserSubject && localStorage) {
      const data = localStorage.getItem('currentUser');
      if (data) {
        this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(data));
      } else {
        this.currentUserSubject = new BehaviorSubject<LoginResponse>(new LoginResponse());
      }
    }
    return this.currentUserSubject;
  }

  public get currentUserValue(): LoginResponse {
    return this.currentUserBehaviorSubject?.value;
  }

  getUserProfile(): Observable<Auth> {
    return this.httpClient.get<Auth>(environment.identityServerUrl + '/Account/GetApplicationUser');
  }

  login(loginRequest: LoginRequest) {
    return this.httpClient.post<any>(environment.identityServerUrl + '/Account/SignIn/', loginRequest);
  }
  logout() {

    var lastLoggedInUserStr = localStorage.getItem("lastLoggedInUser");
    var lastLoggedInUser = JSON.parse(lastLoggedInUserStr)

    localStorage.clear();
    sessionStorage.clear();

    // localStorage.setItem('lastLoggedInUser', JSON.stringify({ displayImageUrl: lastLoggedInUser.displayImageUrl, userName: lastLoggedInUser.userName, }));
    // this.currentUserSubject.next(null);
}
}
