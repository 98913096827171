import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';


export const canActivateGuard: CanActivateFn = (route, state) => {
  if (typeof localStorage !== 'undefined') {
    const currentUserJsonString = localStorage.getItem('currentUser') ?? "";

    if (currentUserJsonString) {
      const loginResponse = JSON.parse(currentUserJsonString);
      var currentDateTime = new Date();
      if (loginResponse
        && loginResponse.applicationUser.userName
        && new Date(loginResponse.tokenInfo.expiresIn) > currentDateTime) {
        return true;
      }
    }
  }

  return inject(Router).createUrlTree(['/'],{ queryParams: { returnUrl: state.url }});
};
