<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/PyxisNameLogo1.svg" class="w-3em" alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button>
        <button class="p-link layout-topbar-button" (click)="showDialog('topright')" severity="secondary">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
        <button class="p-link layout-topbar-button" [routerLink]="'/documentation'">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button>
    </div>
</div>
<p-dialog 
    header="Header" 
    [(visible)]="visible"
    [position]="position" 
    [modal]="true" 
    [style]="{ width: '25rem' }">
        <ng-template pTemplate="header" class=" inline-flex align-items-center justify-content-center gap-2">
            <div class=" inline-flex align-items-center justify-content-center gap-2">
                <p-avatar 
                image="assets/layout/images/account.png" 
                styleClass="mr-2" 
                size="xlarge" 
                shape="circle" />
                <span class="font-bold white-space-nowrap ">
                    siya 
                </span>
            </div>
        </ng-template>
        <span class="p-text-secondary block mb-5">Update your information.</span>
        <div class="content flex  align-items-center  " >
        <div class="flex  align-items-center gap-3 mb-3   "  >
            <h5 class="pi pi-user-edit pl-2"> Edit Account</h5>
        </div>
        <div class="flex align-items-center gap-3 mb-3 ml-3">
            <h5 class="pi pi-sign-out pl-2" routerLink="/LogOut"> Log Out</h5>
        </div>
        
    </div>
</p-dialog>