<p-toast position="top-right" class="toast"></p-toast>
<div class="wrapper" style="background-image: url('assets/layout/images/login_image.jpg');">
    <div class="inner">
        <div class="image-holder">
            <div class="im" style="height: 70px; width: 150px;"><img src="assets/layout/images/PyxisNameLogo1.svg"
                    alt=""></div>
            <div class="mainimg"><img src="assets/layout/images/login1.jpg" alt=""></div>
        </div>
        <form [formGroup]="loginFormGroup" (ngSubmit)="loginuser()">
            <div class="content">
                <h3 style="color: #33a2e2">Login into your Account</h3>
                <h6 style="color: #33a2e2; align-items: center;">Welcome Back</h6>
            </div>
            <div class="form-wrapper">
                <input pInputText type="text" placeholder="UserName" formControlName="username" name="email">
            </div>
            <div class="form-wrapper">
                <input pInputText type="password" placeholder=" Password" formControlName="password" name="pass">
                <i class="zmdi zmdi-lock"></i>
            </div>
            <button pButton >Sign In </button>
        </form>
    </div>
</div>