import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Location } from '@angular/common'
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-log-out',
  standalone: true,
  imports: [DialogModule,ButtonModule],
  templateUrl: './log-out.component.html',
  styleUrl: './log-out.component.scss'
})
export class LogOutComponent {
  logoutDialog: boolean = true;
  changeColor: boolean = true;

  constructor(private Authservice: AuthenticationService,
    private router: Router,
    private location: Location) { }

  ngOnInit(): void {  }
  onClickYes(){
    this.Authservice.logout();
    this.router.navigate(['']);
  }
  onClickNo(){
    this.location.back();
  }

}
